import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Link } from "gatsby-plugin-spinner"
import { filterTirage, regroupementCatFC, regroupementsCategories } from "../services/cadre"
import { categsNoPasse } from "@bko/optionsproduits"

const Categories = (props) => {
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)

  let { categories } = props
  let items = {}
  let ppBool = true
  if (pageProduit.pp.type == "aucun" || pageProduit.pp.type == null) {
    ppBool = false
  }
  categories = categories.filter(
    (categ) =>
      ([null, "aucun"].includes(pageProduit.pp.type) || !categ.cat_ids_originales.every(v => categsNoPasse.indexOf(v) >= 0)) &&
      ((ppBool && !categ.cat_ids_originales.every(v => categsNoPasse.indexOf(v) >= 0) && photosR.tirage == null) ||
        filterTirage(categ, photosR.tirage) ||
        (!ppBool && photosR.tirage == null))
  )
  const rgpm = props.homeMenu ? regroupementsCategories.sort((a, b) => a.order2 - b.order2) : regroupementsCategories
  rgpm.map((edge) => {
    if (categories.filter((e) => edge.categs.includes(e.cat_id)).length >= 1) {
      categories
        .filter((e) => edge.categs.includes(e.cat_id))
        .sort((a, b) => (a.cat_order > b.cat_order ? 1 : -1))
        .map((categ) =>
          typeof items[edge.title] === "undefined"
            ? (items[edge.title] = [{ categ, e: edge, title: false }])
            : items[edge.title].push({ categ, e: edge, title: false })
        )
    }
  })
  return (
    <nav>
      {Object.keys(items).map((c) => (
        <div key={c}>
          <div className={`mcSeparator ${props.bg !== null ? "home" : ""}`}>{c}</div>
          <ul className={`menuCategory mt-2 ${props.bg !== null ? "home" : ""}`} style={{ background: props.bg }}>
            {items[c].map((i) => (
              <li
                key={`${c}-${i.categ.cat_libelle}`}
                className={` ${i.title ? "menuCategoryTitle" : props.pc.categorie && props.pc.categorie.cat_id === i.categ.cat_id ? "active" : ""}`}
              >
                {
                  <Link
                    className={`navbar-item`}
                    to={`/cadres/${i.categ.slug}/`}
                  >
                    {i.categ.cat_libelle.replace(photosR.tirage !== null ? "(option réhausse)" : "", "")}{" "}
                    <span style={{ fontStyle: "italic", fontSize: "0.9em", color: props.homeMenu ? "#FFF" : "#888", marginLeft: "0.5rem" }}>
                      {regroupementCatFC[i.categ.cat_id] && regroupementCatFC[i.categ.cat_id].subtitle || ""}
                    </span>
                  </Link>
                }
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  )
}

Categories.propTypes = {
  categories: PropTypes.array,
  pc: PropTypes.object,
  bg: PropTypes.string,
  homeMenu: PropTypes.bool,
}
Categories.defaultProps = {
  homeMenu: false,
  bg: null,
}

export default Categories
