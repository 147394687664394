import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import Pulse from "react-reveal/Pulse"
import { setDimensions } from "../../actions/cadre"
import ChoixMultivues from "./choixMultivues"
import { setPpType } from "../../actions/passe"
import { categsNoPasse } from "@bko/optionsproduits"

const ChoixDimensions = (props) => {
  const dispatch = useDispatch()
  const pageProduit = useSelector((state) => state.PageProduit)
  const aspectProduit = useSelector((state) => state.aspectProduit)

  const home = typeof window === "undefined" ? true : !window.location.href.includes("/cadre")
  const [popup, setPopup] = useState(false)
  const [hauteur, setHauteur] = useState(
    props.defaultEmpty || pageProduit.dimensionsDefault || aspectProduit.dimensionsSetFrom === "passe" ? "" : pageProduit.hauteur
  )
  const [largeur, setLargeur] = useState(
    props.defaultEmpty || pageProduit.dimensionsDefault || aspectProduit.dimensionsSetFrom === "passe" ? "" : pageProduit.largeur
  )
  useEffect(() => {
    if (pageProduit.dimensionsDefault === false && pageProduit.recouvrement) {
      setHauteur(props.defaultEmpty || pageProduit.dimensionsDefault || aspectProduit.dimensionsSetFrom === "passe" ? "" : pageProduit.hauteur)
      setLargeur(props.defaultEmpty || pageProduit.dimensionsDefault || aspectProduit.dimensionsSetFrom === "passe" ? "" : pageProduit.largeur)
    }
  }, [pageProduit.recouvrement])

  const [hidePp, setHidePp] = useState(false)
  const inputDim = useRef()
  const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip
  useEffect(() => {
    if (pageProduit.dimensionsDefault) {
      inputDim.current.focus()
    }
    setIsMounted(true);
  }, [])
  useEffect(() => {
    if (Math.min(parseFloat(largeur), parseFloat(hauteur)) > 90 || Math.max(parseFloat(largeur), parseFloat(hauteur)) > 140) {
      dispatch(setPpType("aucun"))
      setHidePp(true)
    } else {
      setHidePp(false)
    }
  }, [hauteur, largeur])
  const validDimensions = () => {
    if (hauteur !== "" && parseFloat(hauteur) >= 6 && largeur !== "" && parseFloat(largeur) >= 6) {
      if (parseFloat(hauteur) <= props.pageContext.sizesMax.ba_maxi && parseFloat(largeur) <= props.pageContext.sizesMax.ba_maxi) {
        if (
          (pageProduit.pp.type === null || props.defaultEmpty) &&
          (props.pageContext.categorie === null || !props.pageContext.categorie.cat_ids_originales.every(v => categsNoPasse.indexOf(v) >= 0))
        ) {
          dispatch(setDimensions(parseFloat(largeur), parseFloat(hauteur), true, "home"))
          setPopup(true)
          if (Math.min(parseFloat(largeur), parseFloat(hauteur)) > 90 || Math.max(parseFloat(largeur), parseFloat(hauteur)) > 140) {
            navigate(`/cadres/${props.pageContext.categorie ? `${props.pageContext.categorie.slug}/` : ''}`)
          }
        } else {
          dispatch(setDimensions(parseFloat(largeur), parseFloat(hauteur), false, "home"))
          setPopup(false)
          navigate(home ? "/cadres/" : `/cadres/${props.pageContext.categorie ? `${props.pageContext.categorie.slug}/` : ''}`)
        }
      } else {
        alert("Les dimensions doivent être inférieures à " + props.pageContext.sizesMax.ba_maxi + "cm")
      }
    } else {
      alert("Les dimensions doivent être supérieures à 6cm")
    }
  }

  const onComplete = (result) => {
    if (result) {
      if (pageProduit.largeur >= 6 && pageProduit.hauteur >= 6) {
        setPopup(false)
        navigate(home ? "/cadres/" : `/cadres/${props.pageContext.categorie ? `${props.pageContext.categorie.slug}/` : ''}`)
      }
    } else {
      setPopup(false)
    }
  }

  return (
    <div className="familyHeader">
      {isMounted ? <ReactTooltip id="product" event="click focus" type="info" effect="solid" globalEventOff="click" /> : null}
      <div className="familyHeaderTitle">
        {pageProduit.dimensionsDefault ? "Je renseigne les" : "Les"} dimensions de mon sujet&nbsp;:
        <span
          data-for="product"
          data-multiline="true"
          data-tip={`Renseignez les dimensions réelles du sujet : nous coupons le cadre 2 à 3mm + grand afin d'assurer un jeu parfait entre votre sujet et l'encadrement ${
            ["simple", "multivues"].includes(pageProduit.pp.type)
              ? "Les dimensions de votre sujet ont été diminuées de 5mm de chaque côté afin d&apos;assurer son recouvrement par le passe-partout."
              : ""
          }`}
        >
          <span className="not-active p-0">
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
          </span>
        </span>
      </div>
      <div className="familyHeaderContent mt-2 is-flex " style={{ flexWrap: "wrap", justifyContent: "center" }}>
        {!props.defaultEmpty && pageProduit.dimensionsDefault && (
          <Pulse forever={true} duration={1000}>
            <span className="has-text-primary mr-3">
              <FontAwesomeIcon icon={faHandPointRight} size="3x" />
            </span>
          </Pulse>
        )}
        <div data-info="horizontal">
          <input
            type="number"
            ref={inputDim}
            name="largeur"
            data-info="horizontal"
            value={largeur}
            onChange={(e) => setLargeur(e.target.value)}
            className="input input-number is-large"
            min={6}
            placeholder=" " // Sert à ne pas afficher une erreur
            step=".1"
            required
          />
        </div>
        <span style={{ position: "relative", top: 20 }}>&nbsp;X&nbsp;</span>
        <div data-info="vertical">
          <input
            id="hauteur"
            type="number"
            name="hauteur"
            placeholder=" " // Sert à ne pas afficher une erreur
            onChange={(e) => setHauteur(e.target.value)}
            value={hauteur}
            onKeyPress={(e) => (e.key === "Enter" ? validDimensions() : null)}
            className="input input-number is-large"
            min={6}
            step=".1"
            required
          />
        </div>
        <span style={{ position: "relative", top: 20 }}>&nbsp;cm</span>
        {props.defaultEmpty ||
        (hauteur &&
          largeur &&
          (pageProduit.dimensionsDefault || parseFloat(hauteur) !== pageProduit.hauteur || parseFloat(largeur) !== pageProduit.largeur)) ? (
          <button
            className="button is-primary ml-2"
            type="button"
            onClick={(e) => {window.scroll({top: e.target?.getBoundingClientRect()?.top + window.scrollY - 60, behavior: "smooth"});validDimensions()}}
            style={{ position: "relative", top: 10 }}
            data-cy="valider-dim"
          >
            {props.txtValider || "Valider"}
          </button>
        ) : null}
        <div className={`${props.defaultEmpty ? "is-hidden" : ""}`} style={{ position: "relative", top: 10 }}>
          {hidePp ||
          (props.pageContext.categorie !== null && props.pageContext.categorie.cat_ids_originales.every(v => categsNoPasse.indexOf(v) >= 0)) ||
          aspectProduit.dimensionsSetFrom === "passe" ? null : (
            <ChoixMultivues
              visible={popup}
              onComplete={onComplete}
              sizesMax={props.pageContext.sizesMax}
              largeur={pageProduit.largeur}
              hauteur={pageProduit.hauteur}
              disableNone={pageProduit.largeur < 15 || pageProduit.hauteur < 15}
            />
          )}
        </div>
      </div>
    </div>
  )
}
ChoixDimensions.defaultProps = {
  defaultEmpty: false,
}
ChoixDimensions.propTypes = {
  pageContext: PropTypes.shape().isRequired,
  txtValider: PropTypes.string,
  defaultEmpty: PropTypes.bool,
}

export default ChoixDimensions
